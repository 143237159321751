export const LIST_TRANSAC_REQUEST = 'LIST_TRANSAC_REQUEST';
export const LIST_TRANSAC_ACTU_SUCCESS = 'LIST_TRANSAC_SUCCESS';
export const LIST_TRANSAC_FAIL = 'LIST_TRANSAC_FAIL';
export const LIST_TRANSAC_RESET = 'LIST_TRANSAC_RESET';



export const GET_TRANSAC_REQUEST = 'GET_TRANSAC_REQUEST';
export const GET_TRANSAC_SUCCESS = 'GET_TRANSAC_SUCCESS';
export const GET_TRANSAC_FAIL = 'GET_TRANSAC_FAIL';
export const GET_TRANSAC_RESET = 'GET_TRANSAC_RESET';


