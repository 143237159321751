import DataTable from "react-data-table-component";
import AddNewUserModal from "../../components/common/AddNewUserModal";
import PageHeader from "../../components/common/PageHeader";
import { LeadersListData } from "../../components/Data/AppData";
import { useEffect, useState } from 'react';
import ProjectsListCalls from "../../redux/calls/ProjectsListCalls";
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from "react-router-dom";
import UserListCalls from "../../redux/calls/UserListCalls";
import BrandInfoSmallcard from "../../components/Dashboard/BrandInfoSmallcard";
import { Alert } from "bootstrap";
import React from "react";
import moment from "moment";


const SearchBar =({ filterText, onFilter, onClear }) =>{

    return(
        <div className="input-group flex-nowrap input-group-lg">
            <button type="button" className="input-group-text" id="addon-wrapping"><i className="fa fa-search"></i></button>
            <input type="search" value={filterText}
			onChange={onFilter} className="form-control" placeholder="Recherche" aria-label="search" aria-describedby="addon-wrapping" />
            <button type="button" className="input-group-text add-member-top"  onClick={onClear}><i className="fa fa-plus"></i></button>
        </div>
    )
 }

 const UserList=() =>{
    let navigate = useNavigate();

    const listUser = useSelector((state) => state.listUser);
    const [totalMembers, setTotalMembers] = useState();
    const [totalMembersperDay, setTotalMembersperDay] = useState();

    const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const { loading, error, USERLIST } = listUser;

    const filteredItems = USERLIST.filter(
		item => item.phone && item.phone.toLowerCase().includes(filterText.toLowerCase()) , 
       
	)

    useEffect(() => {
        
        
        let amount=0;
        const total=filteredItems.length === 0 ? 0: (filteredItems.filter(datum => datum.type === "MEMBRE").reduce((total,currentItem) =>  total = total + 1, 0 ));
    
        //const totalperDay=filteredItems.length === 0 ? 0: (filteredItems.filter(datum => datum.type === "MEMBRE")
       // .filter(datum => new Date(moment(datum.date).format('DD MMM YYYY HH:mm')).getTime() === 
       // new Date(moment().format('DD MMM YYYY HH:mm')).getTime() ).reduce((total,currentItem) =>  total = total + 1, 0 ));
 
        // const sum = filteredItems.length == 0 ? 0: filteredItems.map(datum => datum.amount).reduce((a, b) => a + b)
    
        setTotalMembers(total)
       // setTotalMembersperDay(totalperDay)

    

        //Runs on the first render
        //And any time any dependency value changes
      }, [filteredItems]);

/* 

    const toComponentB = (id) => {
        navigate(`/dashboard/editproject/${id}`, {  repalce:true });
        //return <Navigate to={`/dashboard/editproject/${id}`} replace />;
      }; */



      const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
      
    
        return (
            <>
               <div className="container-xxl">

   <div className="row g-3 mb-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2">
   <div className="col-8">

                    <SearchBar onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />

                    </div>
                    <div className="col-12">

            </div> 

            </div>      </div> 
            </> );

    }, [filterText, resetPaginationToggle]);
    const ProjectDataList={

title:'Liste des utilisateurs',
columns:[
  

    {
        
        name: "Prénom et Nom",
        selector:(row)=>row.name,
        sortable: true,
    },
    {
        
        name: "Téléphone",
        selector:(row)=>row.phone,
        sortable: true,
    },
    
    {
        
        name: "Adresse",
        selector:(row)=>row.address,
        sortable: true,
    }

],
rows:
filteredItems.map(p => {

    return { 
          phone:p.phone,
          name:p.name,
          address:p.address,

          id:p.id,


          
      }
  })
    }





        return(
            <div className="container-xxl">
                 <div className="row g-3 mb-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 row-cols-xxl-4">
                    <div className="col-4">
                        <BrandInfoSmallcard title="Nombre d'abonnés" value={totalMembers} iconClass="icofont-data fs-3" />

                    </div>
                    <div className="col-4">
                        <BrandInfoSmallcard title="Abonnés du jour" value={totalMembersperDay} iconClass="icofont-data fs-3" />

                    </div>
                    
                </div>
                <PageHeader headerTitle="Liste des utilisateurs"  />
                <div className="row clearfix g-3">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                            <UserListCalls /> 

                                <DataTable
                                title={ProjectDataList.title}
                                columns={ProjectDataList.columns}
                                data={ProjectDataList.rows}
                                defaultSortField="name"
                                pagination
                                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                subHeader
                                                    subHeaderComponent={subHeaderComponentMemo}
                                                    fixedHeaderScrollHeight="600"
                                selectableRows={false}
                                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
/*                                 highlightOnHover={true}
 *//*                                 onRowClicked={()=>{this.setState({ isAddUserModal:true })}}
 */                                />
                            </div>
                        </div>
                    </div>
                </div>
{/*                 <AddNewUserModal show={ this.state.isAddUserModal } onClose={()=>{ this.setState({ isAddUserModal:false }) }} />
 */}            </div>
        )
    }

    export default UserList;