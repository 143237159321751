import React from "react";
import { useSelector } from "react-redux";
import LeftSide from "./components/Auth/LeftSide";
import Login from "./components/Auth/Login";
import LoginRoot from "./components/Auth/LoginRoot";
import Header from "./components/common/Header";
import PageHeader from "./components/common/PageHeader";
import PageHeader1 from "./components/common/PageHeader1";
import Sidebar from "./components/common/Sidebar";

import Router from "./route";
import RouterAuth from "./route_auth";
import AuthIndex from "./screens/AuthIndex";


/* class App extends React.Component {
  activekey() {
    var res = window.location.pathname
      var baseUrl = process.env.PUBLIC_URL
      baseUrl = baseUrl.split("/");
      res = res.split("/");
      // res = res.length > 1 ? res[res.length-1] : "/";
      res = res.length>0 ? res[baseUrl.length] : "/";
      res= res ? "/"+res : "/";;
      const activeKey1=res;
      return activeKey1
  }
  render(){
    if(this.activekey() === "/login" || this.activekey() === "/sign-up" || this.activekey() === "/password-reset" || this.activekey() === "/2-step-authentication" || this.activekey() === "/page-404"){
      return(
        <div id="mytask-layout" className="theme-indigo">
            <AuthIndex />
        </div>
      )
    }
    return (
      <div id="mytask-layout" className="theme-indigo">
        <Sidebar activekey={this.activekey()} history={this.props.history}/>
          <MainIndex activekey={this.activekey()}/>
        
      </div>
    );
  }
} */


 function App(){

  const userLogin = useSelector((state) => state.userLogin);
  const { error: loginError, loading: loginLoading, userInfo } = userLogin;


    var res = window.location.pathname
      var baseUrl = process.env.PUBLIC_URL
      baseUrl = baseUrl.split("/");
      res = res.split("/");
      // res = res.length > 1 ? res[res.length-1] : "/";
      res = res.length>0 ? res[baseUrl.length] : "/";
      res= res ? "/"+res : "/";;
      const activeKey1=res;
    var actif=   activeKey1;
    if(!userInfo){
  return (
    <div id="mytask-layout" className="theme-indigo">
   
   <div className="main p-2 py-3 p-xl-5 ">
                <div className="body d-flex p-0 p-xl-5">
                    <div className="container-xxl">
                        <div className="row g-0">
                            <LeftSide />
                            <Login />

                        </div>
                    </div>
                </div>
            </div>
            </div> 
            );

      } 
    
      return(
        <div id="mytask-layout" className="theme-indigo">
           <Sidebar /> 
        <div className="main px-lg-4 px-md-4">
       <Header />
        <div className="body d-flex py-lg-3 py-md-2"> 
  <Router />
   </div>
  </div> 

  </div>
      );
     


}
export default App;