import React from "react";
//import { Link } from "react-router-dom";
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import GoogleImg from "../../assets/images/google.svg";
import { useSelector, useDispatch } from 'react-redux';

import { login } from "../../redux/actions/authActions";
import { Button, Spinner, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Alert } from "bootstrap";
import _ from "lodash";
import BorderSpinner from "../UI/BorderSpinner";

 export default function Login () {
    

    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const userLogin = useSelector((state) => state.userLogin);

    const { error:loginError, loading: loginLoading, userInfo,userData } = userLogin;
    const LoginSchema = Yup.object().shape({
        username: Yup.string().required('Nom d\'utilisateur obligatoire'),
        password: Yup.string().required('Mot de passe obligatoire'),
      });
    const formik = useFormik({
        initialValues: {
          username: '',
          password: '',
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
          dispatch(login(values.username, values.password));
        },
      });
      const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
       useEffect(() => {
      
      
         
        
        if (userData) {
          return navigate('/dashboard', { replace: true });
        }
        
        
      }, [navigate,userLogin, userData]);
 

        
            return ( 

            <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100">
                <div className="w-100 p-3 p-md-5 card border-0 bg-dark text-light" style={{maxWidth: "32rem"}}>
                {loginLoading ? (
                                     <Spinner animation="border" variant="secondary" />

        ) : null}
              
                <FormikProvider value={formik}>

                    <Form  autoComplete="off"className="row g-1 p-3 p-md-4"  onSubmit={handleSubmit}>
                        
                        <div className="col-12">
                            <div className="mb-2">
                                <label className="form-label">Nom utilisateur</label>
                                <input type="username"         
        
   {...getFieldProps('username')} name="username" className="form-control form-control-lg" placeholder="" />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-2">
                                {/* <div className="form-label">
                                    <span className="d-flex justify-content-between align-items-center">
                                        Mot de passe
                                        <Link className="text-secondary" to="password-reset">Forgot Password?</Link>
                                    </span>
                                </div> */}
                                <input        
             {...getFieldProps('password')} type="password"  name="password" className="form-control form-control-lg" placeholder="***************" />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label className="form-check-label" for="flexCheckDefault">
Se souvenir                                </label>
                            </div>
                        </div>
                        <div className="col-12 text-center mt-4">
                            <Button type='submit' className="btn btn-lg btn-block btn-light lift text-uppercase" atl="signin">SE CONNECTER</Button>
                        </div>
                       
                        {loginError ? (
          <div className="alert alert-danger" role="alert">
             
                              
          <strong>Erreur:</strong> Veuillez vérifier les informations de connexion 
      </div>
        ) : null}
                    </Form>
                    </FormikProvider>
                 
 
                </div>
                </div>                       


               );
         
           
    }


