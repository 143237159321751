export const LIST_REQUEST = 'LIST_REQUEST';
export const LIST_SUCCESS = 'LIST_SUCCESS';
export const LIST_FAIL = 'LIST_FAIL';
export const LIST_RESET = 'LIST_RESET';

export const CREATE_REQUEST = 'CREATE_REQUEST';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAIL = 'CREATE_FAIL';
export const CREATE_RESET = 'CREATE_RESET';

export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAIL = 'GET_PROJECT_FAIL';
export const GET_PROJECT_RESET = 'GET_PROJECT_RESET';


export const EDIT_PROJECT_REQUEST = 'EDIT_PROJECT_REQUEST';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_FAIL = 'EDIT_PROJECT_FAIL';
export const EDIT_PROJECT_RESET = 'EDIT_PROJECT_RESET';



export const DELELTE_PROJECT_REQUEST = 'DELELTE_PROJECT_REQUEST';
export const DELELTE_PROJECT_SUCCESS = 'DELELTE_PROJECT_SUCCESS';
export const DELELTE_PROJECT_FAIL = 'DELELTE_PROJECT_FAIL';
export const DELELTE_PROJECT_RESET = 'DELELTE_PROJECT_RESET';


