export const LIST_SLIDER_REQUEST = 'LIST_SLIDER_REQUEST';
export const LIST_SLIDER_SUCCESS = 'LIST_SLIDER_SUCCESS';
export const LIST_SLIDER_FAIL = 'LIST_SLIDER_FAIL';
export const LIST_SLIDER_RESET = 'LIST_SLIDER_RESET';


export const CREATE_SLIDER_REQUEST = 'CREATE_SLIDER_REQUEST';
export const CREATE_SLIDER_SUCCESS = 'CREATE_SLIDER_SUCCESS';
export const CREATE_SLIDER_FAIL = 'CREATE_SLIDER_FAIL';
export const CREATE_SLIDER_RESET = 'CREATE_SLIDER_RESET';


export const GET_SLIDER_REQUEST = 'GET_SLIDER_REQUEST';
export const GET_SLIDER_SUCCESS = 'GET_SLIDER_SUCCESS';
export const GET_SLIDER_FAIL = 'GET_SLIDER_FAIL';
export const GET_SLIDER_RESET = 'GET_SLIDER_RESET';


export const EDIT_SLIDER_REQUEST = 'EDIT_SLIDER_REQUEST';
export const EDIT_SLIDER_SUCCESS = 'EDIT_SLIDER_SUCCESS';
export const EDIT_SLIDER_FAIL = 'EDIT_SLIDER_FAIL';
export const EDIT_SLIDER_RESET = 'EDIT_SLIDER_RESET';



export const DELELTE_SLIDER_REQUEST = 'DELELTE_SLIDER_REQUEST';
export const DELELTE_SLIDER_SUCCESS = 'DELELTE_SLIDER_SUCCESS';
export const DELELTE_SLIDER_FAIL = 'DELELTE_SLIDER_FAIL';
export const DELELTE_SLIDER_RESET = 'DELELTE_SLIDER_RESET';