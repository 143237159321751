import axios from 'axios';
import { LIST_REQUEST, LIST_SUCCESS, LIST_FAIL } from '../types/projects';
import { DOMAIN_URL } from '../constants';
import { access_rules_cm } from '../roles';

import { CREATE_DOMAIN_FAIL, CREATE_DOMAIN_REQUEST, CREATE_DOMAIN_SUCCESS, DELELTE_DOMAIN_FAIL, DELELTE_DOMAIN_REQUEST, DELELTE_DOMAIN_SUCCESS, EDIT_DOMAIN_FAIL, EDIT_DOMAIN_REQUEST, EDIT_DOMAIN_SUCCESS, GET_DOMAIN_FAIL, GET_DOMAIN_REQUEST, GET_DOMAIN_SUCCESS, LIST_DOMAIN_SUCCESS, LIST_DOMAIN_FAIL, LIST_DOMAIN_REQUEST } from '../types/domain';
import { actu } from './actuActions';



export const domainget = (id) => async (dispatch,getState) => {
  try {
    dispatch({
      type: GET_DOMAIN_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Token  ${userInfo.token}`,
        },
      };
  
  
      const { data } = await axios.get(`${DOMAIN_URL}/api/domains/${id}/`, config);
  
       
      dispatch({
        type: GET_DOMAIN_SUCCESS,
        payload: data,
      });
      
    }
    else{

      dispatch({
        type: GET_DOMAIN_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
    
  
  } catch (error) {
    dispatch({
      type: GET_DOMAIN_FAIL,
      payload: error,
    });
  }
};
/* export const domains = () => async (dispatch,getState) => {
  try {
    dispatch({
      type: LIST_DOMAIN_REQUEST,
    });

 
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Token  ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${DOMAIN_URL}/api/domains/`, config);

    dispatch({
      type: LIST_DOMAIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LIST_DOMAIN_FAIL,
      payload: error//error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
}; */
export const domaindelete = (id) => async (dispatch,getState) => {
  try {
    dispatch({
      type: DELELTE_DOMAIN_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Token  ${userInfo.token}`,
        },
      };
  
  
      const { data } = await axios.delete(`${DOMAIN_URL}/api/domains/${id}`, config);
  
       
      
      dispatch({
        type: DELELTE_DOMAIN_SUCCESS,
        payload: data,
      });
      
    }
    else{

      dispatch({
        type: DELELTE_DOMAIN_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
    
  
  } catch (error) {
    dispatch({
      type: DELELTE_DOMAIN_FAIL,
      payload:error,
    });
  }
};

export const domain = () => async (dispatch,getState) => {
  try {
    dispatch({
      type: LIST_DOMAIN_REQUEST,
    });

 
    const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Token  ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`${DOMAIN_URL}/api/domains/`, config);
  
      dispatch({
        type: LIST_DOMAIN_SUCCESS,
        payload: data,
      });
      
    }
    else{

      dispatch({
        type: LIST_DOMAIN_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
    
    
  } catch (error) {
    dispatch({
      type: LIST_DOMAIN_FAIL,
      payload: error//error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};
export const domaincreate = (title,files) => async (dispatch,getState) => {
  try {
    dispatch({
      type: CREATE_DOMAIN_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
          'Content-type':'multipart/form-data',
                  Authorization: `Token  ${userInfo.token}`,
        },
      };
  var image=files[0]
  
  
      const { data } = await axios.post(`${DOMAIN_URL}/api/domains/`, { title,image }, config).then(response =>
        { 
      
        const { datas } =  axios.get(`${DOMAIN_URL}/api/domains/`, config);
  
           })
         
       ;
  
       
      
      dispatch({
        type: CREATE_DOMAIN_SUCCESS,
        payload: data,
      });
      
    }
    else{

      dispatch({
        type: CREATE_DOMAIN_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
    
  
  } catch (error) {
    dispatch({
      type:  CREATE_DOMAIN_FAIL,
      payload: error,
    });
  }
};

export const domainedit = (id,title,files) => async (dispatch,getState) => {
  try {
    dispatch({
      type: EDIT_DOMAIN_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
          'Content-type':'multipart/form-data',
          Authorization: `Token  ${userInfo.token}`,
        },
      };
    var image=files[0]
  
      const { data } = await axios.patch(`${DOMAIN_URL}/api/domains/${id}/`, { title ,image}, config);
  
       
      
      dispatch({
        type: EDIT_DOMAIN_SUCCESS,
        payload: data,
      });
      
    }
    else{

      dispatch({
        type: EDIT_DOMAIN_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
    
    
  } catch (error) {
    dispatch({
      type: EDIT_DOMAIN_FAIL,
      payload: error,
    });
  }
};