/* eslint-disable default-param-last */
import { LIST_REQUEST, LIST_SUCCESS, LIST_RESET, GET_FAIL, GET_SUCCESS, GET_RESET, GET_REQUEST } from '../types/users';
import { LIST_FAIL } from '../types/projects';

export const userReducer = (state = { USERLIST: [] }, action) => {
    switch (action.type) {
      case LIST_REQUEST:
        return {
          loading: true,
          USERLIST: [],
        };
  
      case LIST_SUCCESS:
  
        return {
          loading: false,
          success: true,
          USERLIST: action.payload,
        };
  
      case LIST_FAIL:
        return {
          loading: false,
          error: action.payload,
          USERLIST: [],
        };
  
      case LIST_RESET:
        return {
          USERLIST: [],
        };
    
    
        
        
  
      default:
        return state;
    }
  };
  export const userGetReducer = (state= {USER:{}},action)  =>  {
    switch (action.type) {
  
    case GET_REQUEST:
      return {
        loading: true,
        USER: {},
      };
  
    case GET_SUCCESS:
  
      return {
        loading: false,
        success: true,
        USER: action.payload,
      };
  
    case GET_FAIL:
      return {
        loading: false,
        error: action.payload,
        USER: {},
      };
  
    case GET_RESET:
      return {
        USER: {}
      };
    
  default:
    return state
      }
  }

  
