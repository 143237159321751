import { Fragment, useEffect, memo } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ActuForm from '../../components/Pages/ActuForm.js';
import ProjectForm from '../../components/Pages/ProjectForm.js';
import { actuget } from '../actions/actuActions.js';



const ActuGetCall = (props) => {
  const {id}=useParams();
  // ** Store Vars
  const dispatch = useDispatch();
  // ** Get data on mount
  useEffect(() => {
    dispatch(actuget(id));
  }, [dispatch]);

  return (                        
    <ActuForm />
  );
};
export default memo(ActuGetCall);

