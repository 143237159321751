import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageHeader from "../../components/common/PageHeader";
import AdvancedValidationForm from "../../components/Pages/AdvancedValidationForm";
import BasicValidationForm from "../../components/Pages/BasicValidationForm";
import CreateDomainForm from "../../components/Pages/CreateDomainForm ";
import CreateProjectForm from "../../components/Pages/CreateProjectForm ";
import ProjectForm from "../../components/Pages/ProjectForm";
import DomainGetCall from "../../redux/calls/DomainGetCall";
import ProjectsGetCall from "../../redux/calls/ProjectsGetCall";

function BaseDomainForm() {
    const {id } = useParams()
    const getDomain =  useSelector((state) => state.getDomain);
    const { loading, error, DOMAIN } = getDomain;


    if (id && id !=0){
        return(

            <div className="container-xxl">
                <div className="row align-item-center">
                    <div className="col-md-12">
                    <DomainGetCall id={id} />


                    </div>
                </div>
            </div>
        )

    }
     return(

            <div className="container-xxl">
                <PageHeader headerTitle=""  />
                <div className="row align-item-center">
                    <div className="col-md-12">
                       
                        <CreateDomainForm/>
                      
                    </div>
                </div>
            </div>
        )
    }

export default BaseDomainForm;