/* eslint-disable default-param-last */
import { LIST_REQUEST, LIST_SUCCESS, LIST_FAIL, LIST_RESET, LIST_ACTU_REQUEST, LIST_ACTU_SUCCESS, LIST_ACTU_FAIL, LIST_ACTU_RESET, GET_ACTU_REQUEST, GET_ACTU_SUCCESS, GET_ACTU_FAIL, CREATE_ACTU_REQUEST, CREATE_ACTU_SUCCESS, CREATE_ACTU_FAIL, GET_ACTU_RESET, EDIT_ACTU_REQUEST, EDIT_ACTU_SUCCESS, EDIT_ACTU_FAIL, EDIT_ACTU_RESET, DELELTE_ACTU_SUCCESS, DELELTE_ACTU_FAIL, DELELTE_ACTU_RESET, DELELTE_ACTU_REQUEST } from '../types/actu';

export const actuReducer = (state = { ACTULIST: [] }, action) => {
  switch (action.type) {
    case LIST_ACTU_REQUEST:
      return {
        loading: true,
        ACTULIST: [],
      };

    case LIST_ACTU_SUCCESS:

      return {
        loading: false,
        success: true,
        ACTULIST: action.payload,
      };

    case LIST_ACTU_FAIL:
      return {
        loading: false,
        error: action.payload,
        ACTULIST: [],
      };

    case LIST_ACTU_RESET:
      return {
        ACTULIST: [],
      };
  
  
      
      

    default:
      return state;
  }
};
export const actuGetReducer = (state= {ACTU:{}},action)  =>  {
  switch (action.type) {

  case GET_ACTU_REQUEST:
    return {
      loading: true,
      ACTU: {},
    };

  case GET_ACTU_SUCCESS:

    return {
      loading: false,
      success: true,
      ACTU: action.payload,
    };

  case GET_ACTU_FAIL:
    return {
      loading: false,
      error: action.payload,
      ACTU: {},
    };

  case GET_ACTU_RESET:
    return {
      ACTU: {}
    };
  
default:
  return state
    }
}
export const actuEditReducer = (state= {ACTU:{}},action)  =>  {
  switch (action.type) {
    case EDIT_ACTU_REQUEST:
      return {
        loading: true,
        ACTU: {},
      };

    case EDIT_ACTU_SUCCESS:
      
      return {
        loading: false,
        success: true,
        ACTU: action.payload,
      };

    case EDIT_ACTU_FAIL:
      return {
        loading: false,
        error: action.payload,
        ACTU: {},
      };

    case EDIT_ACTU_RESET:
      return {
        ACTU: {},
      };

default:
return state;
}
}

export const actuCreateReducer = (state= {ACTU:{}},action)  =>  {
  switch (action.type) {

    case CREATE_ACTU_REQUEST:
      
      return {
        loading: false,
        success: true,
        ACTU: action.payload,
      };

    case CREATE_ACTU_SUCCESS:
      return {
        loading: false,
        error: action.payload,
        ACTU: {},
      };

    case CREATE_ACTU_FAIL:
      return {
        ACTU: {},
      };

default:
return state;
}
}
export const actuDeleteReducer = (state= {ACTU:{}},action)  =>  {
  switch (action.type) {

  case DELELTE_ACTU_REQUEST:
    return {
      loading: true,
      ACTU: {},
    };

  case DELELTE_ACTU_SUCCESS:

    return {
      //ACTULIST: state.ACTULIST.filter(item=> item.id != action.payload),
      loading: false,
      success: true,
      ACTU: action.payload,
    };

  case DELELTE_ACTU_FAIL:
    return {
      loading: false,
      error: action.payload,
      ACTU: {},
    };

  case DELELTE_ACTU_RESET:
    return {
      ACTU: {}
    };
    
default:
  return state
    }
}