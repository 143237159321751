import axios from 'axios';
import { access_rules_cm } from '../roles';
import { DOMAIN_URL } from '../constants';
import { CREATE_ACTU_FAIL, CREATE_ACTU_REQUEST, CREATE_ACTU_SUCCESS, DELELTE_ACTU_FAIL, DELELTE_ACTU_REQUEST, DELELTE_ACTU_SUCCESS, EDIT_ACTU_FAIL, EDIT_ACTU_REQUEST, EDIT_ACTU_SUCCESS, GET_ACTU_FAIL, GET_ACTU_REQUEST, GET_ACTU_SUCCESS, LIST_ACTU_FAIL, LIST_ACTU_REQUEST, LIST_ACTU_SUCCESS } from '../types/actu';
import { CREATE_SLIDER_FAIL, CREATE_SLIDER_REQUEST, CREATE_SLIDER_SUCCESS, DELELTE_SLIDER_FAIL, DELELTE_SLIDER_REQUEST, DELELTE_SLIDER_SUCCESS, EDIT_SLIDER_FAIL, EDIT_SLIDER_REQUEST, EDIT_SLIDER_SUCCESS, GET_SLIDER_FAIL, GET_SLIDER_REQUEST, GET_SLIDER_SUCCESS, LIST_SLIDER_FAIL, LIST_SLIDER_REQUEST, LIST_SLIDER_SUCCESS } from '../types/slider';
export const slidercreate = (title,description,link,button,files) => async (dispatch,getState) => {
  try {
    dispatch({
      type: CREATE_SLIDER_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();

    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
        //  'Content-type': 'application/json',

         // 'Content-type': 'application/json',
  
         'Content-type':'multipart/form-data',        
          Authorization: `Token  ${userInfo.token}`,
        },
      };
  
      var image=files[0]
      const { data } = await axios.post(`${DOMAIN_URL}/api/slider/`, { image,title,description,link,button }, config).then(response =>
        {
           var a="ok";
      
       
           })
         
       ;
      
       
      
      dispatch({
        type: CREATE_SLIDER_SUCCESS,
        payload: data,
      });
      
    }
    else{

      dispatch({
        type: CREATE_SLIDER_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
   
  } catch (error) {
    dispatch({
      type:  CREATE_SLIDER_FAIL,
      payload: error,
    });
  }
};

export const slideredit = (id,title,description,link,button,files,old_image) => async (dispatch,getState) => {
  try {
    dispatch({
      type: EDIT_SLIDER_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
        //  'Content-type': 'application/json',
  
         'Content-type':'multipart/form-data',        
          Authorization: `Token  ${userInfo.token}`,
        },
      };
      
      const { data } =  files === null ? await axios.patch(`${DOMAIN_URL}/api/slider/${id}/`, { button,link,description,title  }, config):
       await axios.patch(`${DOMAIN_URL}/api/slider/${id}/`, { 'image':files,button,link,description,title  }, config);
      dispatch({
        type: EDIT_SLIDER_SUCCESS,
        payload: data,
      });
    }
    else{

      dispatch({
        type: EDIT_SLIDER_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }


  } catch (error) {

    dispatch({
      type: EDIT_SLIDER_FAIL,
      payload: error,
    });
  }

};


export const sliderget = (id) => async (dispatch,getState) => {
  try {
    dispatch({
      type: GET_SLIDER_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Token  ${userInfo.token}`,
        },
      };
  
  
      const { data } = await axios.get(`${DOMAIN_URL}/api/slider/${id}/`, config);
  
       
      dispatch({
        type: GET_SLIDER_SUCCESS,
        payload: data,
      });
    }
    else{

      dispatch({
        type: GET_SLIDER_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }

  } catch (error) {
    dispatch({
      type: GET_SLIDER_FAIL,
      payload:error ,
    });
  }
};
export const slider = () => async (dispatch,getState) => {
  try {
    dispatch({
      type: LIST_SLIDER_REQUEST
    });

 
    const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Token  ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`${DOMAIN_URL}/api/slider/`, config);
  
      dispatch({
        type: LIST_SLIDER_SUCCESS,
        payload: data,
      });
    }
    else{

      dispatch({
        type: LIST_SLIDER_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
  
  } catch (error) {
    dispatch({
      type: LIST_SLIDER_FAIL,
      payload: error//error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};
export const sliderdelete = (id) => async (dispatch,getState) => {
  try {
    dispatch({
      type: DELELTE_SLIDER_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Token  ${userInfo.token}`,
      },
    };


    const { data } = await axios.delete(`${DOMAIN_URL}/api/slider/${id}`, config);

     
    
    dispatch({
      type: DELELTE_SLIDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELELTE_SLIDER_FAIL,
      payload: error,
    });
  }
};