import { useSelector } from "react-redux";
import { Navigate, Route, useRoutes } from "react-router-dom";
import LayoutLogin from "./components/Auth/LayoutLogin";
import LeftSide from "./components/Auth/LeftSide";
import Login from "./components/Auth/Login";
import LoginRoot from "./components/Auth/LoginRoot";
import DashHome from "./components/common/DashHome";

import Sidebar from "./components/common/Sidebar";
import CreateProjectForm from "./components/Pages/CreateProjectForm ";
import ProjectForm from "./components/Pages/ProjectForm";
import { access_rules_admin, access_rules_cm, access_rules_finance } from "./redux/roles";
import HrDashboard from "./screens/Dashboard/HrDashboard";
import ProjectDashboard from "./screens/Dashboard/ProjectDashboard";
import BaseActuForm from "./screens/OtherPages/BaseActuForm";
import BaseDomainForm from "./screens/OtherPages/BaseDomainForm";
import BaseProjectForm from "./screens/OtherPages/BaseProjectForm";
import BaseSliderForm from "./screens/OtherPages/BaseSliderForm";
import FormsExample from "./screens/OtherPages/FormsExample";
import ActuList from "./screens/Projects/ActuList";
import DomainList from "./screens/Projects/DomainList";
import ProjectList from "./screens/Projects/ProjectList";
import Projects from "./screens/Projects/Projects";
import SliderList from "./screens/Projects/SliderList";
import Tasks from "./screens/Projects/Tasks";
import TransactionList from "./screens/Projects/TransactionList";
import UserList from "./screens/Projects/UserList";


export default function Router(){

    const  { userData }  = useSelector((state) => state.userLogin);
    const  { userInfo }  = useSelector((state) => state.userLogin);


        return useRoutes( 
        [
        
    

    {
        path: '/dashboard',
      
        children:[
            {
                path: '',  element:access_rules_cm.includes(userData)?<ProjectList />:<TransactionList />
            },
            {
                path: 'createproject',  element:access_rules_cm.includes(userData)?<BaseProjectForm/>:<Navigate to="/dashboard" replace /> 
            },
            {
                path: 'listproject',  element:access_rules_cm.includes(userData)?<ProjectList/>:<Navigate to="/dashboard" replace /> 
            },
            {
                path: 'editproject/:id',  element:access_rules_cm.includes(userData)?<BaseProjectForm  />:<Navigate to="/dashboard" replace /> 
            },
            {
                path: 'createactu',  element:access_rules_cm.includes(userData)?<BaseActuForm/>:<Navigate to="/dashboard" replace /> 
            },
            {
                path: 'listactu',  element:access_rules_cm.includes(userData)?<ActuList/>:<Navigate to="/dashboard" replace /> 
            },
            {
                path: 'editactu/:id',  element:access_rules_cm.includes(userData)?<BaseActuForm  />:<Navigate to="/dashboard" replace /> 
            },
            {
                path: 'createdomain',  element:access_rules_cm.includes(userData)?<BaseDomainForm/>:<Navigate to="/dashboard" replace /> 
            },
            {
                path: 'listdomain/',  element:access_rules_cm.includes(userData)?<DomainList/>:<Navigate to="/dashboard" replace /> 
            },
            {
                path: 'editdomain/:id',  element:access_rules_cm.includes(userData)?<BaseDomainForm  />:<Navigate to="/dashboard" replace /> 
            },
            {
                path: 'createslider',  element:access_rules_cm.includes(userData)?<BaseSliderForm/>:<Navigate to="/dashboard" replace /> 
            },
            {
                path: 'listslider/',  element:access_rules_cm.includes(userData)?<SliderList/>:<Navigate to="/dashboard" replace /> 
            },
            {
                path: 'editslider/:id',  element:access_rules_cm.includes(userData)?<BaseSliderForm  />: <Navigate to="/dashboard" replace /> 
            },
            {
                path: 'listtransaction',  element:access_rules_finance.includes(userData)?<TransactionList/>:<Navigate to="/dashboard" replace /> 
            },
            {
                path: 'listuser',  element:access_rules_finance.includes(userData)?<UserList/>:<Navigate to="/dashboard" replace /> 
            },


         /*    {
                path: 'deleteproject',  element:<EditProjectForm/>    // "date-fns": "^1.29.0",

            }, */
        ],
        


    },


    { path: '*', element: <Navigate to="/dashboard" replace /> },
    { path: 'logout', element: <LoginRoot /> },

 
    
    
])
    
    
    
    ;
}