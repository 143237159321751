/* eslint-disable default-param-last */
import { LIST_REQUEST, LIST_SUCCESS, LIST_FAIL, LIST_RESET ,
GET_PROJECT_REQUEST, GET_PROJECT_FAIL,GET_PROJECT_RESET,GET_PROJECT_SUCCESS,
EDIT_PROJECT_REQUEST,EDIT_PROJECT_FAIL,EDIT_PROJECT_RESET,EDIT_PROJECT_SUCCESS,
DELELTE_PROJECT_FAIL,DELELTE_PROJECT_REQUEST,DELELTE_PROJECT_RESET,DELELTE_PROJECT_SUCCESS, CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAIL

} from '../types/projects';

export const projectsReducer = (state = { PROJECTLIST: [] }, action) => {
  switch (action.type) {
    case LIST_REQUEST:
      return {
        loading: true,
        PROJECTLIST: [],
      };

    case LIST_SUCCESS:

      return {
        loading: false,
        success: true,
        PROJECTLIST: action.payload,
      };

    case LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
        PROJECTLIST: [],
      };

    case LIST_RESET:
      return {
        PROJECTLIST: [],
      };
   
      case LIST_SUCCESS:
  
        return {
          loading: false,
          success: true,
          PROJECTLIST: action.payload,
        };
  
      case LIST_FAIL:
        return {
          loading: false,
          error: action.payload,
          PROJECTLIST: [],
        };
  
      case LIST_RESET:
        return {
          PROJECTLIST: [],
        };
      
      

    default:
      return state;
  }
};
export const projectGetReducer = (state= {PROJECT:{}},action)  =>  {
  switch (action.type) {

  case GET_PROJECT_REQUEST:
    return {
      loading: true,
      PROJECT: {},
    };

  case GET_PROJECT_SUCCESS:

    return {
      loading: false,
      success: true,
      PROJECT: action.payload,
    };

  case GET_PROJECT_FAIL:
    return {
      loading: false,
      error: action.payload,
      PROJECT: {},
    };

  case GET_PROJECT_RESET:
    return {
      PROJECT: {}
    };
    
default:
  return state
    }
}
export const projectEditReducer = (state= {PROJECT:{}},action)  =>  {
  switch (action.type) {
    case EDIT_PROJECT_REQUEST:
      return {
        loading: true,
        PROJECT: {},
      };

    case EDIT_PROJECT_SUCCESS:
      
      return {
        loading: false,
        success: true,
        PROJECT: action.payload,
      };

    case EDIT_PROJECT_FAIL:
      return {
        loading: false,
        error: action.payload,
        PROJECT: {},
      };

    case EDIT_PROJECT_RESET:
      return {
        PROJECT: {},
      };

default:
return state;
}
}

export const projectCreateReducer = (state= {PROJECT:{}},action)  =>  {
  switch (action.type) {

    case CREATE_REQUEST:
      return {
        loading:true,
        PROJECT: {},
      };
      
     

    case CREATE_SUCCESS:
    return {
      loading: false,
      success: true,
      PROJECT: action.payload,
    };
     

    case CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
        PROJECT: {},
      };
      

default:
return state;
}
}
export const projectDeleteReducer = (state= {PROJECT:{}},action)  =>  {
  switch (action.type) {

  case GET_PROJECT_REQUEST:
    return {
      loading: true,
      PROJECT: {},
    };

  case GET_PROJECT_SUCCESS:

    return {
      loading: false,
      success: true,
      PROJECT: action.payload,
    };

  case GET_PROJECT_FAIL:
    return {
      loading: false,
      error: action.payload,
      PROJECT: {},
    };

  case GET_PROJECT_RESET:
    return {
      PROJECT: {}
    };
    
default:
  return state
    }
}