import { Fragment, useEffect, memo } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ProjectForm from '../../components/Pages/ProjectForm.js';

import { projectget } from '../actions/projectsActions.js';


const ProjectsGetCall = (props) => {
  const {id}=useParams();
  // ** Store Vars
  const dispatch = useDispatch();
  // ** Get data on mount
  useEffect(() => {
    dispatch(projectget(id));
  }, [dispatch]);

  return (                        <ProjectForm />
  );
};
export default memo(ProjectsGetCall);

