import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import PageHeader from "../../components/common/PageHeader";
import { useState } from 'react';
import ProjectsListCalls from "../../redux/calls/ProjectsListCalls";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from "react-router-dom";
import { projectdelete, projects } from "../../redux/actions/projectsActions";
import Modaler from "../../components/common/Modal";
import { access_rules_cm } from "../../redux/roles";




 const ProjectList=() =>{
    let navigate = useNavigate();
    const dispatch= useDispatch();
    const initialVals={

        "isAddUserModal":false,
        "currentid":0

    }
   // const [isAddUserModal, setisAddUserModal] = useState(false);
   const  userData  = useSelector((state) => state.userLogin);

    const listProject = useSelector((state) => state.listProject);

    const { loading, error, PROJECTLIST } = listProject; 
   // const [currentid,setcurrentid]=useState(false);
   const [values,setValues]=useState(initialVals)
   const [isConfirmed,setIsConfirmed]=useState(false)

    const setisAddUserModalTrue  = (id)=>{

    setValues({
        'isAddUserModal':true,
        'currentid':id
    });
       // setcurrentid(id)
    }
    useEffect(() =>  {
     
      
      
        if(isConfirmed === true){
        
            console.log(' reload!')
          //  dispatch(actudelete(values.currentid))
             setValues({
                'isAddUserModal':false,
                'currentid':0,
    
            });
    
           setIsConfirmed(false)
          // dispatch(actu());
//refreshme();
           //    alert('reload!')
           //ACTULIST.filter(item => item.id != values.currentid)

           
           //dispatch(actu());
           console.log('listitem!')
         //  window.location.reload();
          // const reload=()=>window.location.reload();
        //  navigate('/dashboard/listactu', { replace: true });


        }
    else{
        dispatch(projects());
        console.log(' ending!')

    }
     

      }, []); 
 /*    useEffect(() => {
        dispatch(projects());

      }, []); */

    const toComponentB = (id) => {
        navigate(`/dashboard/editproject/${id}`, {  repalce:true });

        //return <Navigate to={`/dashboard/editproject/${id}`} replace />;
      };
   
    /*   const Delete = (id) => {
        dispatch(projectdelete(id));
        setValues(false,0);
        //navigate(`/dashboard/editproject/${id}`, {  repalce:true });
        //return <Navigate to={`/dashboard/editproject/${id}`} replace />;
      }; */
      const Delete = async (id)  => {

        await dispatch(projectdelete(id));
         setValues({
             'isAddUserModal':false,
             'currentid':0,
         });
         dispatch(projects())
      
     return   ;  //    navigate(`/dashboard/listactu/`, {  repalce:true });
     ;
     }
    const ProjectDataList={

title:'Liste des projets',
columns:[
    {
        
        name: "Titre",
        selector:(row)=>row.title,
        sortable: true,
        filterable: true,
                cell:row=><><span className="fw-bold ms-1">{row.title}</span></>,
        minWidth:"200px"
    },
    {
        
        name: "Description",
        selector:(row)=>row.description,
        sortable: true,
    },
    {
        
        name: "Budget",
        selector:(row)=>row.budget,
        sortable: true,
    },
    {
        
        name: "Estimation",
        selector:(row)=>row.estimated_budget,
        sortable: true,
    },
  
    {
        name: "ACTION",
        selector: (row)=>row.id,
        sortable: true,
        cell:(row)=><div className="btn-group" role="group" aria-label="Basic outlined example">

{   access_rules_cm.includes(userData.userData) ?  
                  
                  <button type="button" onClick={() => {
                    toComponentB(row.id);
                  }} className="btn btn-outline-secondary"><i className="icofont-edit text-success"></i></button>:null

              

                                  
                 
                    } 
{   access_rules_cm.includes(userData.userData)?  
                  
                  <button type="button" onClick={() => {
                    setisAddUserModalTrue(row.id);
                  }}  className="btn btn-outline-secondary deleterow"><i className="icofont-ui-delete text-danger"></i></button>:null

              

                                  
                 
                    } 
         
                </div>
    }
],
rows:
PROJECTLIST.map(p => {

    return { title:p.title,
          description:p.description.substring(0,50)+'...',
          budget:p.budget,
          estimated_budget:p.estimated_budget,
          id:p.id,


          
      }
  })
    }





        return(
            <div className="container-xxl">
                <PageHeader headerTitle="Liste des projets"  />
                <div className="row clearfix g-3">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
{/*                             <ProjectsListCalls /> 
 */}
                                <DataTable
                                title={ProjectDataList.title}
                                columns={ProjectDataList.columns}
                                data={ProjectDataList.rows}
                                defaultSortField="title"
                                pagination
                                selectableRows={false}
                                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
/*                                 highlightOnHover={true}
 *//*                                 onRowClicked={()=>{this.setState({ isAddUserModal:true })}}
 */                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modaler onCancel={()=>{setIsConfirmed(false);}} onConfirm={()=>{setIsConfirmed(true);Delete(values.currentid)} }onClose={ values.isAddUserModal } show={values.isAddUserModal} />
{/*                 <AddNewUserModal show={ this.state.isAddUserModal } onClose={()=>{ this.setState({ isAddUserModal:false }) }} />
 */}            </div>
        )
    }

    export default ProjectList;