export const LIST_ACTU_REQUEST = 'LIST_ACTU_REQUEST';
export const LIST_ACTU_SUCCESS = 'LIST_ACTU_SUCCESS';
export const LIST_ACTU_FAIL = 'LIST_ACTU_FAIL';
export const LIST_ACTU_RESET = 'LIST_ACTU_RESET';


export const CREATE_ACTU_REQUEST = 'CREATE_ACTU_REQUEST';
export const CREATE_ACTU_SUCCESS = 'CREATE_ACTU_SUCCESS';
export const CREATE_ACTU_FAIL = 'CREATE_ACTU_FAIL';
export const CREATE_ACTU_RESET = 'CREATE_ACTU_RESET';


export const GET_ACTU_REQUEST = 'GET_ACTU_REQUEST';
export const GET_ACTU_SUCCESS = 'GET_ACTU_SUCCESS';
export const GET_ACTU_FAIL = 'GET_ACTU_FAIL';
export const GET_ACTU_RESET = 'GET_ACTU_RESET';


export const EDIT_ACTU_REQUEST = 'EDIT_ACTU_REQUEST';
export const EDIT_ACTU_SUCCESS = 'EDIT_ACTU_SUCCESS';
export const EDIT_ACTU_FAIL = 'EDIT_ACTU_FAIL';
export const EDIT_ACTU_RESET = 'EDIT_ACTU_RESET';



export const DELELTE_ACTU_REQUEST = 'DELELTE_ACTU_REQUEST';
export const DELELTE_ACTU_SUCCESS = 'DELELTE_ACTU_SUCCESS';
export const DELELTE_ACTU_FAIL = 'DELELTE_ACTU_FAIL';
export const DELELTE_ACTU_RESET = 'DELELTE_ACTU_RESET';