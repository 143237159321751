import React from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import {
  projectcreate,
  projectedit,
  projectget,
} from "../../redux/actions/projectsActions";
import ProjectsGetCall from "../../redux/calls/ProjectsGetCall";
import { Alert } from "react-bootstrap";
import { actuedit } from "../../redux/actions/actuActions";

export default function ActuForm(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialer, setInitialer] = useState({});

  const getActu = useSelector((state) => state.getActu);

  const { loading, error, ACTU } = getActu;
  const initialval = {
    title: "",
    description: "",
    files: null,
  };

  const ProjectSchema = Yup.object().shape({
    title: Yup.string().required("Titre est obligatoire"),
    description: Yup.string().required("Description est obligatoire"),
  });
  const images = () => {
    var ima = [];
    ACTU.project_images.map((v) => ima.push(v.image));
    return ima;
  };

  //const listProject = useSelector((state) => state.listProject);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: _.isEmpty(ACTU) ? {} : ACTU,
    validationSchema: ProjectSchema,
    onSubmit: () => {
      if (id && id != 0) {
        if (values.files === null) {
          dispatch(
            actuedit(id, values.title, values.description, values.files, [])
          );
        } else {
          var ima = [];
          ACTU.actu_images.map((v) => ima.push(v.id));
          dispatch(
            actuedit(id, values.title, values.description, values.files, ima)
          );
        }
      }
      if (!error) {
        navigate("/dashboard/listactu", { replace: true });
      }
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    setFieldValue,
    handleSubmit,
    getFieldProps,
  } = formik;

  /*   if (userInfo) {
          navigate('/dashboard/listproject', { replace: true });
        }
      }, [navigate, userInfo] */

  return (
    <div className="card mb-3">
      {/*                             <ProjectsGetCall id={id} />
       */}
      <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
        <h6 className="mb-0 fw-bold ">MODIFIER </h6>
      </div>
      <div className="card-body">
        {error ? <Alert variant="danger">{error}</Alert> : null}

        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <div className="row g-3 align-items-center">
              <div className="col-md-6">
                <label htmlFor="titre" className="form-label">
                  Titre
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="titre"
                  {...getFieldProps("title")}
                  required
                />
              </div>

              {/*    <div className="col-md-6">
                            <label className="form-label">Budget</label>
                            <input type="text" className="form-control" id="budget"  {...getFieldProps('budget')} required />
                        </div>
                   <div className="col-md-6">
                            <label htmlFor="emailaddress" className="form-label">Email Address</label>
                            <input type="email" className="form-control" id="emailaddress" required />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="admitdate" className="form-label">Date</label>
                            <input type="date" className="form-control" id="admitdate" required />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="admittime" className="form-label">Time</label>
                            <input type="time" className="form-control" id="admittime" required />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="formFileMultiple" className="form-label"> File Upload</label>
                            <input className="form-control" type="file" id="formFileMultiple" multiple="" required />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Gender</label>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios11" value="option1"  />
                                        <label className="form-check-label" htmlFor="exampleRadios11">
                                        Male
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios22" value="option2" />
                                        <label className="form-check-label" htmlFor="exampleRadios22">
                                        Female
                                        </label>
                                    </div>
                                </div>
                            </div> 
                        </div>*/}
              <div className="col-md-12">
                <label htmlFor="description" className="form-label">
                  Description
                </label>
                <textarea
                  className="form-control"
                  id="description"
                  {...getFieldProps("description")}
                  rows="3"
                ></textarea>
              </div>
              <div className="col-md-12">
                <h5>Images courantes</h5>
                {ACTU.actu_images &&
                  ACTU.actu_images.map((file, i) => (
                    <img
                      className="card-img-top"
                      key={i}
                      style={{
                        width: "12rem",
                        height: "12rem",
                        margin: "1.5rem",
                      }}
                      src={file.image}
                    />
                  ))}
              </div>
              <div className="col-md-12">
                <label htmlFor="file">Remplacez vos images</label>

                <UploadComponent setFieldValue={setFieldValue} />
                {values.files &&
                  values.files.map((file, i) => (
                    <img
                      className="card-img-top"
                      key={i}
                      style={{
                        width: "12rem",
                        height: "12rem",
                        margin: "1.5rem",
                      }}
                      alt={file.name}
                      src={file.preview}
                    />
                  ))}
              </div>
              {/*    <div className="col-md-12">
                        <UploadComponent setFieldValue={setFieldValue} />
                   {values.files &&
                    values.files.map((file, i) => (
                        <img
                        className="card-img-top"
                        key={i}
                        style={{ width: "12rem", height: "12rem", margin: "1.5rem" }}
                        alt={file.name}
                        src={file.preview}
                      />
                 
                    ))}   
          {/*               <label htmlFor="file">Multiple files upload</label>

                  <UploadComponent setFieldValue={setFieldValue} /> 
             {/*      {values.files &&
                    values.files.map((file, i) => (
                        <img
                        className="card-img-top"
                        key={i}
                        style={{ width: "12rem", height: "12rem", margin: "1.5rem" }}
                        alt={file.name}
                        src={file}
                      />
                 
                    ))}  
                </div>*/}
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-primary mt-4"
            >
              Modifier
            </button>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
}
const UploadComponent = (props) => {
  const { setFieldValue } = props;
  const [files, setFiles] = useState([]);

  //const [setFieldValue] = useState([]);
  var filess = [];
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/jpg,image/png",
    onDrop: (acceptedFiles) => {
      setFieldValue(
        "files",
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const thumbs = files.map((file) => (
    <img
      className="card-img-top"
      key={file.name}
      style={{ width: "12rem", height: "12rem", margin: "1.5rem" }}
      alt={file.name}
      src={file.preview}
    />
  ));

  return (
    <div>
      <div
        style={{ background: "#eee", minHeight: "100px" }}
        {...getRootProps({ className: "dropzone" })}
      >
        <input {...getInputProps()} />
        <p className="text-center">
          Faites glisser et déposez des fichiers ici, ou cliquez pour
          sélectionner des fichiers
        </p>
      </div>
      <aside>{thumbs}</aside>
    </div>
  );
};
