import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import "./assets/scss/main.scss"
import * as serviceWorker from './serviceWorker';

import { HelmetProvider } from 'react-helmet-async';

// ** Redux Imports
import { Provider } from 'react-redux';
import store from './redux/store';

ReactDOM.render(
   <React.StrictMode>
   <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
      <App />
      </Provider>
    </BrowserRouter>
  </HelmetProvider>,
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

