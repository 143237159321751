import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageHeader from "../../components/common/PageHeader";
import AdvancedValidationForm from "../../components/Pages/AdvancedValidationForm";
import BasicValidationForm from "../../components/Pages/BasicValidationForm";
import CreateActuForm from "../../components/Pages/CreateActuForm";
import CreateProjectForm from "../../components/Pages/CreateProjectForm ";
import CreateSliderForm from "../../components/Pages/CreateSliderForm";
import ProjectForm from "../../components/Pages/ProjectForm";
import ActuGetCall from "../../redux/calls/ActuGetCall";
import DomainGetCall from "../../redux/calls/DomainGetCall";
import ProjectsGetCall from "../../redux/calls/ProjectsGetCall";
import SliderGetCall from "../../redux/calls/SliderGetCall";

function BaseSliderForm() {
    const {id } = useParams()
    const getSlider =  useSelector((state) => state.getSlider);
    const { loading, error, SLIDER } = getSlider;


    if (id && id !=0){
        return(

            <div className="container-xxl">
                <div className="row align-item-center">
                    <div className="col-md-12">
                    <SliderGetCall id={id} />


                    </div>
                </div>
            </div>
        )

    }
     return(

            <div className="container-xxl">
                <PageHeader headerTitle="Forms"  />
                <div className="row align-item-center">
                    <div className="col-md-12">
                       
                        <CreateSliderForm/>
                      
                    </div>
                </div>
            </div>
        )
    }

export default BaseSliderForm;