/* eslint-disable default-param-last */
import { DELELTE_ACTU_REQUEST, DELELTE_ACTU_RESET } from '../types/actu';
import { LIST_REQUEST, LIST_SUCCESS, LIST_FAIL, LIST_RESET, LIST_DOMAIN_REQUEST, LIST_DOMAIN_ACTU_SUCCESS, LIST_DOMAIN_FAIL, LIST_DOMAIN_RESET, GET_DOMAIN_REQUEST, GET_DOMAIN_SUCCESS, GET_DOMAIN_FAIL, EDIT_DOMAIN_REQUEST, EDIT_DOMAIN_SUCCESS, EDIT_DOMAIN_FAIL, CREATE_DOMAIN_REQUEST, CREATE_DOMAIN_SUCCESS, GET_DOMAIN_RESET, EDIT_DOMAIN_RESET, CREATE_DOMAIN_FAIL, DELELTE_DOMAIN_SUCCESS, DELELTE_DOMAIN_FAIL, DELELTE_DOMAIN_REQUEST, LIST_DOMAIN_SUCCESS } from '../types/domain';

export const domainReducer = (state = { DOMAINLIST: [] }, action) => {
  switch (action.type) {
    case LIST_DOMAIN_REQUEST:
      return {
        loading: true,
        DOMAINLIST: [],
      };

    case LIST_DOMAIN_SUCCESS:

      return {
        loading: false,
        success: true,
        DOMAINLIST: action.payload,
      };

    case LIST_DOMAIN_FAIL:
      return {
        loading: false,
        error: action.payload,
        DOMAINLIST: [],
      };

    case LIST_DOMAIN_RESET:
      return {
        DOMAINLIST: [],
      };
  
  
      
      

    default:
      return state;
  }
};
export const domainGetReducer = (state= {DOMAIN:{}},action)  =>  {
  switch (action.type) {

  case GET_DOMAIN_REQUEST:
    return {
      loading: true,
      DOMAIN: {},
    };

  case GET_DOMAIN_SUCCESS:

    return {
      loading: false,
      success: true,
      DOMAIN: action.payload,
    };

  case GET_DOMAIN_FAIL:
    return {
      loading: false,
      error: action.payload,
      DOMAIN: {},
    };

  case GET_DOMAIN_RESET:
    return {
      DOMAIN: {}
    };
  
default:
  return state
    }
}
export const domaineditReducer = (state= {DOMAIN:{}},action)  =>  {
  switch (action.type) {
    case EDIT_DOMAIN_REQUEST:
      return {
        loading: true,
        DOMAIN: {},
      };

    case EDIT_DOMAIN_SUCCESS:
      
      return {
        loading: false,
        success: true,
        DOMAIN: action.payload,
      };

    case EDIT_DOMAIN_FAIL:
      return {
        loading: false,
        error: action.payload,
        DOMAIN: {},
      };

    case EDIT_DOMAIN_RESET:
      return {
        DOMAIN: {},
      };

default:
return state;
}
}

export const domainCreateReducer = (state= {DOMAIN:{}},action)  =>  {
  switch (action.type) {

    case CREATE_DOMAIN_REQUEST:
      
      return {
        loading: false,
        success: true,
        DOMAIN: action.payload,
      };

    case CREATE_DOMAIN_SUCCESS:
      return {
        loading: false,
        error: action.payload,
        DOMAIN: {},
      };

    case CREATE_DOMAIN_FAIL:
      return {
        DOMAIN: {},
      };

default:
return state;
}
}




export const domainDeleteReducer = (state= {DOMAIN:{}},action)  =>  {
  switch (action.type) {

  case DELELTE_DOMAIN_REQUEST:
    return {
      loading: true,
      DOMAIN: {},
    };

  case DELELTE_DOMAIN_SUCCESS:

    return {
      loading: false,
      success: true,
      DOMAIN: action.payload,
    };

  case DELELTE_DOMAIN_FAIL:
    return {
      loading: false,
      error: action.payload,
      DOMAIN: {},
    };

  case DELELTE_ACTU_RESET:
    return {
      DOMAIN: {}
    };
    
default:
  return state
    }
}