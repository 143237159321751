import { ActionTypes } from '@mui/base';
import axios from 'axios';
import { access_rules_cm } from '../roles';
import { DOMAIN_URL } from '../constants';
import { CREATE_ACTU_FAIL, CREATE_ACTU_REQUEST, CREATE_ACTU_SUCCESS, DELELTE_ACTU_FAIL, DELELTE_ACTU_REQUEST, DELELTE_ACTU_SUCCESS, EDIT_ACTU_FAIL, EDIT_ACTU_REQUEST, EDIT_ACTU_SUCCESS, GET_ACTU_FAIL, GET_ACTU_REQUEST, GET_ACTU_SUCCESS, LIST_ACTU_FAIL, LIST_ACTU_REQUEST, LIST_ACTU_SUCCESS } from '../types/actu';

export const actucreate = (title,description,files) => async (dispatch,getState) => {
  try {
    dispatch({
      type: CREATE_ACTU_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
          'Content-type':'multipart/form-data',        
          Authorization: `Token  ${userInfo.token}`,
        },
      };
  
  
      const { data } = await axios.post(`${DOMAIN_URL}/api/actus/`, { title,description }, config).then(response =>
        {files.map(file => {
           var image=file;
           var info=response.data.id;
           var display_order=1;
            const {datas}=    axios.post(`${DOMAIN_URL}/api/actu_images/`, {info, image,display_order}, config);
      
       
           })
         
       });
  
       
      
      dispatch({
        type: CREATE_ACTU_SUCCESS,
        payload: data,
      });
    }
    else{

      dispatch({
        type: CREATE_ACTU_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }


  
  } catch (error) {
    dispatch({
      type:  CREATE_ACTU_FAIL,
      payload: error,
    });
  }
};

export const actuedit = (id,title,description,files,image_ids) => async (dispatch,getState) => {
  const { data } ={};
  try {
    dispatch({
      type: EDIT_ACTU_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
         // 'Content-type': 'application/json',
          'Content-type':'multipart/form-data',
  
          Authorization: `Token  ${userInfo.token}`,
        },
      };
      if(files != null){
  
        image_ids.map(id=> {
    
         const del= axios.delete(`${DOMAIN_URL}/api/actu_images/${id}/`, config);
    
        })
         data  = await axios.patch(`${DOMAIN_URL}/api/actus/${id}/`, { title,description }, config).then(response =>
          {files.map(file => {
             var image=file;
             var info=response.data.id;
             var display_order=1;
              const {datas}=    axios.post(`${DOMAIN_URL}/api/actu_images/`, {image,display_order,'info':info}, config);
        
         
             })
           
         });
       
    
       }
  
  else {
     data = await axios.patch(`${DOMAIN_URL}/api/actus/${id}/`, { title,description }, config);
  
  }
  
       
      
      dispatch({
        type: EDIT_ACTU_SUCCESS,
        payload: data,
      });
    }
    else{

      dispatch({
        type: EDIT_ACTU_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }

  } catch (error) {
    dispatch({
      type: EDIT_ACTU_FAIL,
      payload: error,
    });
  }
};


export const actuget = (id) => async (dispatch,getState) => {
  try {
    dispatch({
      type: GET_ACTU_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Token  ${userInfo.token}`,
      },
    };


    const { data } = await axios.get(`${DOMAIN_URL}/api/actus/${id}/`, config);

     
    dispatch({
      type: GET_ACTU_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ACTU_FAIL,
      payload:error ,
    });
  }
};
export const actu = () => async (dispatch,getState) => {
  try {
    dispatch({
      type: LIST_ACTU_REQUEST
    });

 
    const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Token  ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`${DOMAIN_URL}/api/actus/`, config);
  
      dispatch({
        type: LIST_ACTU_SUCCESS,
        payload: data,
      });
    }
    else{

      dispatch({
        type: LIST_ACTU_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
  
  } catch (error) {
    dispatch({
      type: LIST_ACTU_FAIL,
      payload: error//error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};
export const actudelete = (id) => async (dispatch,getState) => {
  try {
    dispatch({
      type: DELELTE_ACTU_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Token  ${userInfo.token}`,
        },
      };
  
  
      const { data } = await axios.delete(`${DOMAIN_URL}/api/actus/${id}/`, config);
     // const { datas } = await axios.get(`${DOMAIN_URL}/api/actus/`, config);
     //alert('denj')
  
      dispatch({
        type: DELELTE_ACTU_SUCCESS,
        payload: data,
      });
    }
    else{

      dispatch({
        type: DELELTE_ACTU_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
    
   // dispatch(actu());
  } catch (error) {
    dispatch({
      type: DELELTE_ACTU_FAIL,
      payload: error,
    });
  }
};