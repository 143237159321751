import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import AddNewUserModal from "../../components/common/AddNewUserModal";
import PageHeader from "../../components/common/PageHeader";
import { LeadersListData } from "../../components/Data/AppData";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from "react-router-dom";
import ActuListCalls from "../../redux/calls/ActuListCalls";
import Modaler from "../../components/common/Modal";
import { actu, actudelete } from "../../redux/actions/actuActions";
import { access_rules_cm } from "../../redux/roles";
import { fr } from "date-fns/locale";
import { format } from "date-fns";




 const ActuList=() =>{
    let navigate = useNavigate();
    const dispatch= useDispatch();
    const initialVals={

        "isAddUserModal":false,
        "currentid":0

    }

    const listActu = useSelector((state) => state.listActu);
    const  userData  = useSelector((state) => state.userLogin);

    const { loading, error, ACTULIST } = listActu;
    const [values,setValues]=useState(initialVals)
    const [isConfirmed,setIsConfirmed]=useState(false)
    const [Actulister,setActulister]=useState(ACTULIST)



    const setisAddUserModalTrue  = (id)=>{  

    setValues({
        'isAddUserModal':true,
        'currentid':id,
    });
       // setcurrentid(id)
    }
     useEffect(() =>  {
     
        async function Deleter ()  {
            const actuer = await    dispatch(actudelete(values.currentid));
            
           // setUsers(users);
          }
          
       
          async function refreshme ()  {
            const actuers = await    dispatch(actu());
            
           // setUsers(users);
          }
          

       
      
        if(isConfirmed === true){
        
            console.log(' reload!')
          //  dispatch(actudelete(values.currentid))
             setValues({
                'isAddUserModal':false,
                'currentid':0,
    
            });
    
           setIsConfirmed(false)
          // dispatch(actu());
//refreshme();
           //    alert('reload!')
           //ACTULIST.filter(item => item.id != values.currentid)

           
           //dispatch(actu());
           console.log('listitem!')
         //  window.location.reload();
          // const reload=()=>window.location.reload();
        //  navigate('/dashboard/listactu', { replace: true });


        }
    else{
        dispatch(actu());
        console.log(' ending!')

    }
     

      }, []);   
      const Delete = async (id)  => {

       await dispatch(actudelete(id));
        setValues({
            'isAddUserModal':false,
            'currentid':0,
        });
        dispatch(actu())
     
    return   ;  //    navigate(`/dashboard/listactu/`, {  repalce:true });
    ;
    }
        //navi

    const toComponentB = (id) => {
        dispatch(actu())

        navigate(`/dashboard/editactu/${id}`, {  repalce:true });
        //return <Navigate to={`/dashboard/editproject/${id}`} replace />;
      };
    const ProjectDataList={

title:'Liste des actualités',
columns:[
    {
        
        name: "Titre",
        selector:(row)=>row.title,
        sortable: true,
        cell:row=><><span className="fw-bold ms-1">{row.title}</span></>,
        minWidth:"200px"
    },
    {
        
        name: "Description",
        selector:(row)=>row.description,
        sortable: true,
    },
    {
        
        name: "Date",
        selector:(row)=>row.date,
        sortable: true,
        cell:row=><><span className="fw-bold ms-1">{format(new Date(row.date), 'dd MMM yyyy HH:mm',{locale:fr})}</span></>,

    },
  
  
    {
        name: "ACTION",
        selector: (row)=>row.id,
        sortable: true,
        cell:(row)=>(<div className="btn-group" role="group" aria-label="Basic outlined example">
              { access_rules_cm.includes(userData.userData) ?  
                     <button type="button" onClick={() => {
                        toComponentB(row.id);
                      }} className="btn btn-outline-secondary"><i className="icofont-edit text-success"></i></button>:null

              

                                  
                 
                    } 
                             {  access_rules_cm.includes(userData.userData) ?  

                    <button type="button" onClick={() => {
          setisAddUserModalTrue(row.id);}} className="btn btn-outline-secondary deleterow"><i className="icofont-ui-delete text-danger"></i></button>:null
        }  
        
        </div>)
    }
],
rows:
ACTULIST.map(p => {

    return { title:p.title,
          description:p.description.substring(0,50)+'...',
          date:p.date,
          id:p.id,


          
      }
  })
    }





        return(
            <div className="container-xxl">
                <PageHeader headerTitle="Liste des actualités"  />
                <div className="row clearfix g-3">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">                             
{/*                             <ActuListCalls /> 
 */} 
                                <DataTable
                                title={ProjectDataList.title}
                                columns={ProjectDataList.columns}
                                data={ProjectDataList.rows}
                                defaultSortField="title"
                                pagination
                                selectableRows={false}
                                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
/*                                 highlightOnHover={true}
 *//*                                 onRowClicked={()=>{this.setState({ isAddUserModal:true })}}
 */                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modaler onCancel={()=>{setIsConfirmed(false);}} onConfirm={()=>{setIsConfirmed(true);Delete(values.currentid)} } onClose={ values.isAddUserModal } show={values.isAddUserModal} />

{/*                 <AddNewUserModal show={ this.state.isAddUserModal } onClose={()=>{ this.setState({ isAddUserModal:false }) }} />
 */}            </div>
        )
    }

    export default ActuList;