import axios from 'axios';
import { LIST_REQUEST,LIST_RESET, LIST_SUCCESS, LIST_FAIL, GET_PROJECT_REQUEST, GET_PROJECT_SUCCESS, GET_PROJECT_FAIL, EDIT_PROJECT_REQUEST, CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAIL, DELELTE_PROJECT_REQUEST, EDIT_PROJECT_SUCCESS, EDIT_PROJECT_FAIL, DELELTE_PROJECT_FAIL } from '../types/projects';
import { DOMAIN_URL } from '../constants';
import { DELELTE_ACTU_SUCCESS } from '../types/actu';
import { access_rules_cm } from '../roles';



export const projectcreate = (title,description,estimated_budget,files,) => async (dispatch,getState) => {
  try {
    dispatch({
      type: CREATE_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
  /*         'Content-type': 'application/json',
  
   */        'Content-type':'multipart/form-data',
          Authorization: `Token  ${userInfo.token}`,
        },
      };
      var budget="0"
  
  
      const { data } = await axios.post(`${DOMAIN_URL}/api/projects/`, { title,description,estimated_budget, budget}, config).then(response =>
     {files.map(file => {
        var image=file;
        var project=response.data.id;
        var display_order=1;
         const {datas}=    axios.post(`${DOMAIN_URL}/api/project_images/`, {project, image,display_order}, config);
   
    
        })
      
    });
     /*  const  {data_files } = files.map((file)=> {
  
        var image=file;
              await axios.post(`${DOMAIN_URL}/api/projects_images/`, { image}, config);
  
    })
        */
      
      dispatch({
        type: CREATE_SUCCESS,
        payload: data,
      });
    }
    else{

      dispatch({
        type: CREATE_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }


   
  } catch (error) {
    dispatch({
      type:  CREATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const projectedit = (id,title,description,estimated_budget,files,image_ids) => async (dispatch,getState) => {
  const  { data } = {};
  try {
    dispatch({
      type: EDIT_PROJECT_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
         'Content-type':'multipart/form-data',
          Authorization: `Token  ${userInfo.token}`,
        },
      };
     if(files != null){
  
      image_ids.map(id=> {
  
       const del= axios.delete(`${DOMAIN_URL}/api/project_images/${id}/`, config);
  
      })
        data  = await axios.patch(`${DOMAIN_URL}/api/projects/${id}/`, { title,description,estimated_budget }, config).then(response =>
        {files.map(file => {
           console.log('thiepa')
           var image=file;
           var project=response.data.id;
           var display_order=1;
            const {datas}=    axios.post(`${DOMAIN_URL}/api/project_images/`, {project, image,display_order}, config);
      
       
           })
         
       });
     
  
     }
  
  
      /* const {dar}=project_images.map(filer => {
        var idz=filer.id;
    
            axios.delete(`${DOMAIN_URL}/api/project_images/${idz}`, config);
   
    
        }) */
        else {
           data  = await axios.patch(`${DOMAIN_URL}/api/projects/${id}/`, { title,description,estimated_budget }, config)
  
        }
          /* .then(response =>
     {files.map(file => {
           var image=file;
           var project=response.data.id;
           var display_order=1;
            const {datas}=    axios.post(`${DOMAIN_URL}/api/project_images/`, {project, image,display_order}, config);
      
       
           }) 
         
       });*/
     
         
    
  
       
      
      dispatch({
        type: EDIT_PROJECT_SUCCESS,
        payload: data,
      });
    }
    else{

      dispatch({
        type: EDIT_PROJECT_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }


   
  } catch (error) {
    dispatch({
      type: EDIT_PROJECT_FAIL,
      payload: error,
    });
  }
};


export const projectget = (id) => async (dispatch,getState) => {
  try {
    dispatch({
      type: GET_PROJECT_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Token  ${userInfo.token}`,
        },
      };
  
  
      const { data } = await axios.get(`${DOMAIN_URL}/api/projects/${id}/`, config);
  
       
      dispatch({
        type: GET_PROJECT_SUCCESS,
        payload: data,
      });
    }
    else{

      dispatch({
        type: GET_PROJECT_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }



  } catch (error) {
    dispatch({
      type: GET_PROJECT_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};
export const projects = () => async (dispatch,getState) => {
  try {
    dispatch({
      type: LIST_REQUEST,
    });

 
    const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Token  ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`${DOMAIN_URL}/api/projects/`, config);
  
      dispatch({
        type: LIST_SUCCESS,
        payload: data,
      });
    }
    else{

      dispatch({
        type: LIST_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }

  
  } catch (error) {
    dispatch({
      type: LIST_FAIL,
      payload: error//error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};
export const projectdelete = (id) => async (dispatch,getState) => {
  try {
    dispatch({
      type: DELELTE_PROJECT_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();
    const {
      userLogin: { userData },
    } = getState();
    if(access_rules_cm.includes(userData)){

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Token  ${userInfo.token}`,
        },
      };
  
  
      const { data } = await axios.delete(`${DOMAIN_URL}/api/projects/${id}`, config);
  
       
      
      dispatch({
        type: DELELTE_ACTU_SUCCESS,
        payload: data,
      });
    }
    else{

      dispatch({
        type: DELELTE_PROJECT_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
 
  } catch (error) {
    dispatch({
      type: DELELTE_PROJECT_FAIL,
      payload: error.message,
    });
  }
};