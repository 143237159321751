import { Fragment, useEffect, memo } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DomainForm from '../../components/Pages/DomainForm.js';
import ProjectForm from '../../components/Pages/ProjectForm.js';
import { domainget } from '../actions/domainActions.js';



const DomainGetCall = (props) => {
  const {id}=useParams();
  // ** Store Vars
  const dispatch = useDispatch();
  // ** Get data on mount
  useEffect(() => {
    dispatch(domainget(id));
  }, [dispatch]);

  return (                        <DomainForm />
  );
};
export default memo(DomainGetCall);

