export const LIST_DOMAIN_REQUEST = 'LIST_DOMAIN_REQUEST';
export const LIST_DOMAIN_SUCCESS = 'LIST_DOMAIN_SUCCESS';
export const LIST_DOMAIN_FAIL = 'LIST_DOMAIN_FAIL';
export const LIST_DOMAIN_RESET = 'LIST_DOMAIN_RESET';

export const CREATE_DOMAIN_REQUEST = 'CREATE_REQUEST';
export const CREATE_DOMAIN_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_DOMAIN_FAIL = 'CREATE_FAIL';
export const CREATE_DOMAIN_RESET = 'CREATE_RESET';


export const GET_DOMAIN_REQUEST = 'GET_DOMAIN_REQUEST';
export const GET_DOMAIN_SUCCESS = 'GET_DOMAIN_SUCCESS';
export const GET_DOMAIN_FAIL = 'GET_DOMAIN_FAIL';
export const GET_DOMAIN_RESET = 'GET_DOMAIN_RESET';


export const EDIT_DOMAIN_REQUEST = 'EDIT_DOMAIN_REQUEST';
export const EDIT_DOMAIN_SUCCESS = 'EDIT_DOMAIN_SUCCESS';
export const EDIT_DOMAIN_FAIL = 'EDIT_DOMAIN_FAIL';
export const EDIT_DOMAIN_RESET = 'EDIT_DOMAIN_RESET';



export const DELELTE_DOMAIN_REQUEST = 'DELELTE_DOMAIN_REQUEST';
export const DELELTE_DOMAIN_SUCCESS = 'DELELTE_DOMAIN_SUCCESS';
export const DELELTE_DOMAIN_FAIL = 'DELELTE_DOMAIN_FAIL';
export const DELELTE_DOMAIN_RESET = 'DELELTE_DOMAIN_RESET';