/* eslint-disable default-param-last */
import { LIST_REQUEST, LIST_SUCCESS, LIST_FAIL, LIST_RESET } from '../types/domain';
import { GET_TRANSAC_FAIL, GET_TRANSAC_REQUEST, GET_TRANSAC_RESET, GET_TRANSAC_SUCCESS, LIST_TRANSAC_ACTU_SUCCESS, LIST_TRANSAC_FAIL, LIST_TRANSAC_REQUEST, LIST_TRANSAC_RESET } from '../types/transaction';

export const transactReducer = (state = { TRANSACLIST: [] }, action) => {
    switch (action.type) {
      case LIST_TRANSAC_REQUEST:
        return {
          loading: true,
          TRANSACLIST: [],
        };
  
      case LIST_TRANSAC_ACTU_SUCCESS:
  
        return {
          loading: false,
          success: true,
          TRANSACLIST: action.payload,
        };
  
      case LIST_TRANSAC_FAIL:
        return {
          loading: false,
          error: action.payload,
          TRANSACLIST: [],
        };
  
      case LIST_TRANSAC_RESET:
        return {
          ACTULIST: [],
        };
    
    
        
        
  
      default:
        return state;
    }
  };
  export const transactGetReducer = (state= {TRANSAC:{}},action)  =>  {
    switch (action.type) {
  
    case GET_TRANSAC_REQUEST:
      return {
        loading: true,
        TRANSAC: {},
      };
  
    case GET_TRANSAC_SUCCESS:
  
      return {
        loading: false,
        success: true,
        TRANSAC: action.payload,
      };
  
    case GET_TRANSAC_FAIL:
      return {
        loading: false,
        error: action.payload,
        TRANSAC: {},
      };
  
    case GET_TRANSAC_RESET:
      return {
        TRANSAC: {}
      };
    
  default:
    return state
      }
  }

  
