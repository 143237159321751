/* eslint-disable default-param-last */
import { LIST_REQUEST, LIST_SUCCESS, LIST_FAIL, LIST_RESET, LIST_ACTU_REQUEST, LIST_ACTU_SUCCESS, LIST_ACTU_FAIL, LIST_ACTU_RESET, GET_ACTU_REQUEST, GET_ACTU_SUCCESS, GET_ACTU_FAIL, CREATE_ACTU_REQUEST, CREATE_ACTU_SUCCESS, CREATE_ACTU_FAIL, GET_ACTU_RESET, EDIT_ACTU_REQUEST, EDIT_ACTU_SUCCESS, EDIT_ACTU_FAIL, EDIT_ACTU_RESET, DELELTE_ACTU_SUCCESS, DELELTE_ACTU_FAIL, DELELTE_ACTU_RESET, DELELTE_ACTU_REQUEST } from '../types/actu';
import { CREATE_SLIDER_FAIL, CREATE_SLIDER_REQUEST, CREATE_SLIDER_SUCCESS, DELELTE_SLIDER_FAIL, DELELTE_SLIDER_REQUEST, DELELTE_SLIDER_RESET, DELELTE_SLIDER_SUCCESS, EDIT_SLIDER_FAIL, EDIT_SLIDER_REQUEST, EDIT_SLIDER_RESET, EDIT_SLIDER_SUCCESS, GET_SLIDER_FAIL, GET_SLIDER_REQUEST, GET_SLIDER_RESET, GET_SLIDER_SUCCESS, LIST_SLIDER_FAIL, LIST_SLIDER_REQUEST, LIST_SLIDER_RESET, LIST_SLIDER_SUCCESS } from '../types/slider';

export const sliderReducer = (state = { SLIDERLIST: [] }, action) => {
  switch (action.type) {
    case LIST_SLIDER_REQUEST:
      return {
        loading: true,
        SLIDERLIST: [],
      };

    case LIST_SLIDER_SUCCESS:

      return {
        loading: false,
        success: true,
        SLIDERLIST: action.payload,
      };

    case LIST_SLIDER_FAIL:
      return {
        loading: false,
        error: action.payload,
        SLIDERLIST: [],
      };

    case LIST_SLIDER_RESET:
      return {
        SLIDERLIST: [],
      };
  
  
      
      

    default:
      return state;
  }
};
export const sliderGetReducer = (state= {SLIDER:{}},action)  =>  {
  switch (action.type) {

  case GET_SLIDER_REQUEST:
    return {
      loading: true,
      SLIDER: {},
    };

  case GET_SLIDER_SUCCESS:

    return {
      loading: false,
      success: true,
      SLIDER: action.payload,
    };

  case GET_SLIDER_FAIL:
    return {
      loading: false,
      error: action.payload,
      SLIDER: {},
    };

  case GET_SLIDER_RESET:
    return {
      SLIDER: {}
    };
  
default:
  return state
    }
}
export const sliderEditReducer = (state= {SLIDER:{}},action)  =>  {
  switch (action.type) {
    case EDIT_SLIDER_REQUEST:
      return {
        loading: true,
        SLIDER: {},
      };

    case EDIT_SLIDER_SUCCESS:
      
      return {
        loading: false,
        success: true,
        SLIDER: action.payload,
      };

    case EDIT_SLIDER_FAIL:
      return {
        loading: false,
        error: action.payload,
        SLIDER: {},
      };

    case EDIT_SLIDER_RESET:
      return {
        SLIDER: {},
      };

default:
return state;
}
}

export const sliderCreateReducer = (state= {SLIDER:{}},action)  =>  {
  switch (action.type) {

    case CREATE_SLIDER_REQUEST:
      
      return {
        loading: false,
        success: true,
        SLIDER: action.payload,
      };

    case CREATE_SLIDER_SUCCESS:
      return {
        loading: false,
        error: action.payload,
        SLIDER: {},
      };

    case CREATE_SLIDER_FAIL:
      return {
        SLIDER: {},
      };

default:
return state;
}
}
export const sliderDeleteReducer = (state= {SLIDER:{}},action)  =>  {
  switch (action.type) {

  case DELELTE_SLIDER_REQUEST:
    return {
      loading: true,
      SLIDER: {},
    };

  case DELELTE_SLIDER_SUCCESS:

    return {
      loading: false,
      success: true,
      SLIDER: action.payload,
    };

  case DELELTE_SLIDER_FAIL:
    return {
      loading: false,
      error: action.payload,
      SLIDER: {},
    };

  case DELELTE_SLIDER_RESET:
    return {
      SLIDER: {}
    };
    
default:
  return state
    }
}