import axios from 'axios';
import { access_rules_finance } from '../roles';

import { DOMAIN_URL } from '../constants';
import { CREATE_ACTU_FAIL, CREATE_ACTU_REQUEST, CREATE_ACTU_SUCCESS, DELELTE_ACTU_FAIL, DELELTE_ACTU_REQUEST, DELELTE_ACTU_SUCCESS, EDIT_ACTU_FAIL, EDIT_ACTU_REQUEST, EDIT_ACTU_SUCCESS, GET_ACTU_FAIL, GET_ACTU_SUCCESS, LIST_ACTU_REQUEST } from '../types/actu';
import { GET_TRANSAC_FAIL, GET_TRANSAC_REQUEST, GET_TRANSAC_SUCCESS, LIST_TRANSAC_ACTU_SUCCESS, LIST_TRANSAC_FAIL, LIST_TRANSAC_REQUEST } from '../types/transaction';





export const transactget = (id) => async (dispatch,getState) => {
  try {
    dispatch({
      type: GET_TRANSAC_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Token  ${userInfo.token}`,
      },
    };


    const { data } = await axios.get(`${DOMAIN_URL}/api/transaction/${id}/`, config);

     
    dispatch({
      type: GET_TRANSAC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TRANSAC_FAIL,
      payload:error ,
    });
  }
};
export const transaction = () => async (dispatch,getState) => {
  try {
    dispatch({
      type: LIST_TRANSAC_REQUEST,
    });

 
    const {
      userLogin: { userInfo },
    } = getState();

    const {userLogin: { userData },
    } = getState();

    if(access_rules_finance.includes(userData)){

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Token  ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`${DOMAIN_URL}/api/transaction/`, config);
   


      dispatch({
        type: LIST_TRANSAC_ACTU_SUCCESS,
        payload: data,
      });
      
    }
    else{
      console.log(userData)
      dispatch({
        type: LIST_TRANSAC_FAIL,
        payload: {},//error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
    
  
  } catch (error) {
    dispatch({
      type: LIST_TRANSAC_FAIL,
      payload: error//error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};
