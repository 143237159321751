

// ----------------------------------------------------------------------

import { useDispatch, useSelector } from "react-redux";
import LeftSide from "./LeftSide";
import Login from "./Login";

import _ from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/reducers/authReducers";
export default function LoginRoot() {
  const navigate=useNavigate()
  const dispatch=useDispatch()
  
  const userInfo= useSelector((state) => state.logout);
  const userData= useSelector((state) => state.logout);



  useEffect(() => {
    dispatch(logout())
    if (_.isEmpty(userInfo) &&_.isEmpty(userData)  ) {
      return navigate('/login',{replace:true});
    }
  
  }, []);

  return (
    <div id="mytask-layout" className="theme-indigo">

       <Login />
    </div>
 
     

  );
}
